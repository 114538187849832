import { useEffect, useState } from "react";
// for work_images
import work1 from "../assets/images/work_images/1.jpg";
import work2 from "../assets/images/work_images/2.jpg";
import work3 from "../assets/images/work_images/3.jpg";
import work4 from "../assets/images/work_images/4.jpg";
import work5 from "../assets/images/work_images/5.jpg";
import work6 from "../assets/images/work_images/6.jpg";
import work7 from "../assets/images/work_images/7.jpg";
import work8 from "../assets/images/work_images/8.jpg";
import work9 from "../assets/images/work_images/9.jpg";
// works small images
import workSmall1 from "../assets/images/work_images/small/1.jpg";
import workSmall2 from "../assets/images/work_images/small/2.jpg";
import workSmall3 from "../assets/images/work_images/small/3.jpg";
import workSmall4 from "../assets/images/work_images/small/4.jpg";
import workSmall5 from "../assets/images/work_images/small/5.jpg";
import workSmall6 from "../assets/images/work_images/small/6.jpg";
import workSmall7 from "../assets/images/work_images/small/7.jpg";
import workSmall8 from "../assets/images/work_images/small/8.jpg";
import workSmall9 from "../assets/images/work_images/small/9.jpg";
// works portfolio images
import rsz_awerino from "../assets/images/portfolio/gfx/rsz_awerino.png";
import vikings from "../assets/images/portfolio/documentaries/vikings.jpg";
import pottypaul from "../assets/images/portfolio/documentaries/pottypaul.jpg";
import otava from "../assets/images/portfolio/documentaries/otava.jpg";
import readysteadycraft from "../assets/images/portfolio/tv/readysteadycraft.jpeg";

import Feathers from "../assets/images/portfolio/acting/Feathers.png";
import PullUpIcon from "../assets/images/portfolio/acting/PullUpIcon.jpg";
import Sonder from "../assets/images/portfolio/acting/Sonder.png";
import overp from "../assets/images/portfolio/acting/over.jpg";
import animation1 from "../assets/images/portfolio/animation/animation1.png";
import cardimgredditsmall from "../assets/images/portfolio/gfx/cardimgredditsmall.jpg";
import superfasthosting from "../assets/images/portfolio/gfx/superfasthosting.png";
import farmerteds from "../assets/images/portfolio/video/farmerteds.png";
import juliamichaelsanxiety from "../assets/images/portfolio/video/juliamichaelsanxiety.jpg";
import Saal from "../assets/images/portfolio/video/Saal.jpg";
import thebookseat from "../assets/images/portfolio/video/thebookseat.png";
import thegate from "../assets/images/portfolio/video/thegate.png";
import vfxhostingadvert from "../assets/images/portfolio/video/vfxhostingadvert.png";
import hostmenow from "../assets/images/portfolio/website/hostmenow.png";
import minecraft from "../assets/images/portfolio/website/minecraft.png";
import ngs from "../assets/images/portfolio/website/ngs.png";
import over from "../assets/images/portfolio/acting/over.jpg";
import tof from "../assets/images/portfolio/video/Tof.png";


import HostMeNow from "../assets/images/slider/hostmenow.png";
import ecologix from "../assets/images/slider/ecologix.png";
import evercity from "../assets/images/portfolio/gfx/evercity.png";

import whatisaixplain from "../assets/images/portfolio/video/aixplain/whatisaixplain.jpg";
import designoverview from "../assets/images/portfolio/video/aixplain/designoverview.jpg";

import godot from "../assets/images/portfolio/video/aixplain/godot.jpg";

import belesprittrailer from "../assets/images/portfolio/video/aixplain/belesprittrailer.jpg";
import hostmenowseo from "../assets/images/portfolio/marketing/hostmenowseo.jpg";

import coovouch from "../assets/images/portfolio/marketing/coovouch.jpg";


import discover from "../assets/images/portfolio/video/aixplain/Discover.png";


import banner3 from "../assets/images/portfolio/gfx/banner3.png";

import voiceclone from "../assets/images/portfolio/gfx/aixplain/voiceclone.png";
import designexplained from "../assets/images/portfolio/gfx/aixplain/designexplained.png";






// blog post images
import reddit from "../assets/images/blog_images/mine/reddit.jpg";
import BestPayPalWebHosting from "../assets/images/blog_images/mine/Best-PayPal-Web-Hosting.png";
import Dogecoin from "../assets/images/blog_images/mine/Dogecoin.png";
import fivecrypto from "../assets/images/blog_images/mine/fivecrypto.png";
import whentochoose from "../assets/images/blog_images/mine/aixplain/whentochoose.jpg";
import kareemhands from "../assets/images/blog_images/mine/aixplain/kareemhands.jpg";
import acllandscape from "../assets/images/blog_images/mine/aixplain/acllandscape.jpg";
import genai from "../assets/images/blog_images/mine/aixplain/genai.png";
import kubeflowtoflyte from "../assets/images/blog_images/mine/aixplain/kubeflowtoflyte.png";




import NAACL2022 from "../assets/images/blog_images/mine/aixplain/NAACL2022.jpg";

import TranslationPipelines from "../assets/images/blog_images/mine/aixplain/TranslationPipelines.jpg";




// blog post images
import blog6 from "../assets/images/blog_images/6.jpg";
import blog4 from "../assets/images/blog_images/4.jpg";
import blog2 from "../assets/images/blog_images/2.jpg";
import blog1 from "../assets/images/blog_images/1.jpg";
import blog3 from "../assets/images/blog_images/3.jpg";
import blog5 from "../assets/images/blog_images/5.jpg";
// blog image small
import blogSmall6 from "../assets/images/blog_images/small/6.jpg";
import blogSmall4 from "../assets/images/blog_images/small/4.jpg";
import blogSmall2 from "../assets/images/blog_images/small/2.jpg";
import blogSmall1 from "../assets/images/blog_images/small/1.jpg";
import blogSmall3 from "../assets/images/blog_images/small/3.jpg";
import blogSmall5 from "../assets/images/blog_images/small/5.jpg";

import img1 from "../assets/images/slider/brand-1.png";
import img2 from "../assets/images/slider/brand-2.png";
import img3 from "../assets/images/slider/brand-3.png";


import img4 from "../assets/images/slider/brand-4.png";
import img5 from "../assets/images/slider/brand-5.png";
//  icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
import icon2 from "../assets/images/icons/icon-3.svg";
import icon3 from "../assets/images/icons/icon-4.svg";
import icon4 from "../assets/images/icons/icon-5.svg";
import icon5 from "../assets/images/icons/icon-6.svg";
// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaBlogger, FaRegUser, FaAward } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
        {
      id: "25",
      tag: "Marketing",
      title: "Paid Ads, COO Vouch - aiXplain",
      img: coovouch,
      imgSmall: coovouch,
      bg: "#E9FFF1",
	  project: "Paid Ads, COO Vouch. - aiXplain",
      client: "aiXplain",
      langages: "English",
      link: "",
      linkText: "N/A",
      description:
        "Launched a sucessfull paid advertising campaign.",
    },
	  {
      id: "27",
      tag: "Marketing",
      title: "SEO - HostMeNow",
      img: hostmenowseo,
      imgSmall: hostmenowseo,
      bg: "#E9FFF1",
	  project: "SEO. - HostMeNow",
      client: "HostMeNow",
      langages: "English",
      link: "",
      linkText: "https://HostMeNow.org",
      description:
        "I implemented a comprehensive marketing strategy for HostMeNow website hosting solutions, developing SEO-optimized landing pages and KB articles, while also utilizing inbound and outbound marketing techniques. Additionally, I incorporated parasite SEO tactics and strategic backlinks to enhance performance. This well-rounded approach resulted in a significant increase in traffic, driving more visitors to the site.",
    },
	   {
      id: "21",
      tag: "Video",
      title: "Bel Esprit by aiXplain - AI, Your Way",
      img: belesprittrailer,
      imgSmall: belesprittrailer,
      bg: "#E9FAFF",
	  project: "Bel Esprit by aiXplain",
      client: "aiXplain",
      langages: "English",
      link: "https://youtu.be/o3Zx1aykgC4",
      linkText: "youtu.be/o3Zx1aykgC4",
      description:
        "Bel Esprit by aiXplain: The world's first AI solution architect. - Promo Video.",
    },
	{
      id: "26",
      tag: "Video",
      title: "How To Build a Game with ChatGPT",
      img: godot,
      imgSmall: godot,
      bg: "#E9FAFF",
	  project: "How To Build a Game with ChatGPT",
      client: "aiXplain",
      langages: "English",
      link: "https://youtu.be/n2M5iddRD3I",
      linkText: "youtu.be/n2M5iddRD3I",
      description:
        "How to build a game with ChatGPT video demo.",
    },
     {
      id: "22",
      tag: "Video",
      title: "What is aiXplain?",
      img: whatisaixplain,
      imgSmall: whatisaixplain,
      bg: "#E9FAFF",
	  project: "What is aiXplain?",
      client: "aiXplain",
      langages: "English",
      link: "https://youtu.be/gk1IJBPts34",
      linkText: "youtu.be/gk1IJBPts34",
      description:
        "Motion graphic channel promotional video.	 To view more of my aiXplain content, please visit the aiXplain YouTube channel.",
    },
	{
      id: "23",
      tag: "Video",
      title: "Design overview",
      img: designoverview,
      imgSmall: designoverview,
      bg: "#E9FAFF",
	  project: "Design overview",
      client: "aiXplain",
      langages: "English",
      link: "https://youtu.be/2r5e8mxxMCw",
      linkText: "youtu.be/2r5e8mxxMCw",
      description:
        "Design: Simplify AI with No-Code Solutions. - Video Tutorial ",
    },
	{
      id: "1",
      tag: "Video",
      title: "Farmer Teds Promo Video",
      img: farmerteds,
      imgSmall: farmerteds,
      bg: "#E9FAFF",
	  project: "Farmer Teds Promo Video",
      client: "Farmer Teds",
      langages: "English",
      link: "https://youtu.be/x3fHrXi738U",
      linkText: "youtu.be/x3fHrXi738U",
      description:
        "Farmer Teds promotional video - filmed, directed and edited by me",
    },
    {
      id: "2",
      tag: "Video",
      title: "Potty Paul - Documentary",
      img: pottypaul,
      imgSmall: pottypaul,
      bg: "#E9FAFF",
	  project: "Potty Paul - Documentary",
      client: "self-made",
      langages: "English",
      link: "https://youtu.be/-STa_C2ClBY",
      linkText: "youtu.be/-STa_C2ClBY",
      description:
        "  Documentary portrays a local man who has raised a lot of money for charities by growing and selling plants from his garden.",
    },
    {
      id: "3",
      tag: "Video",
      title: "The Gate - Documentary",
      img: thegate,
      imgSmall: thegate,
      bg: "#E9FAFF",
	  project: "The Gate - Documentary",
      client: "self-made",
      langages: "English",
      link: "https://vimeo.com/213369000/29967553dc",
      linkText: "Vimeo",
      description:
        "  1967 – John Lennon from The Beatles wrote the song “Strawberry Fields Forever”. 50 years later. At the location “Strawberry Fields” in Liverpool.",
    },
    {
      id: "4",
      tag: "Video",
      title: "Otava River - Documentary",
      img: otava,
      imgSmall: otava,
      bg: "#E9FAFF",
	  project: "Otava River - Documentary",
      client: "self-made",
      langages: "Premiere Pro",
      link: "https://youtu.be/XQ4JCVL-vsg",
      linkText: "youtu.be/XQ4JCVL-vsg",
      description:
        "  An obsevartional documentary on the Otava river in Pisek - Czech Republic",
    },
    {
      id: "5",
      tag: "Acting",
      title: "Pull Up",
      img: PullUpIcon,
      imgSmall: PullUpIcon,
      bg: "#FFFAE9",
	  project: "Pull Up",
      client: "N/A",
      langages: "Silent",
      link: "https://youtu.be/XQVBvptELt4",
      linkText: "youtu.be/XQVBvptELt4",
      description:
        "  Short fitness comedy sketch - Czech Republic. (2020)",
    },
    {
      id: "6",
      tag: "Acting",
      title: "Feathers",
      img: Feathers,
      imgSmall: Feathers,
      bg: "#FFFAE9",
	  project: "Feathers",
      client: "N/A",
      langages: "Silent",
      link: "https://youtu.be/xotOg1K6wRw",
      linkText: "youtu.be/xotOg1K6wRw",
      description:
        "  A short sketch about an annoying parrot - Czech Republic. (2020)",
    },
    {
      id: "7",
      tag: "Acting",
      title: "Sonder",
      img: Sonder,
      imgSmall: Sonder,
      bg: "#FFFAE9",
	  project: "Sonder",
      client: "N/A",
      langages: "Silent",
      link: "https://youtu.be/018TRxdrSN0",
      linkText: "youtu.be/018TRxdrSN0",
      description:
        " A lost wallet sketch - Czech Republic. (2020)",
    },
    {
      id: "8",
      tag: "Web Design",
      title: "HostMeNow.org - Site Design",
      img: hostmenow,
      imgSmall: hostmenow,
      bg: "#F4F4FF",
	  project: "HostMeNow.org - Site Design",
      client: "Myself",
      langages: "Adobe Muse, html, CSS",
      link: "https://www.hostmenow.org",
      linkText: "HostMeNow.org",
      description:
        "Website Design for my company (HostMeNow LTD - privacy-focused website & server hosting)",
    },
	{
      id: "9",
      tag: "Web Design",
      title: "Wesite Design - Accountancy Agency",
      img: ngs,
      imgSmall: ngs,
      bg: "#F4F4FF",
	  project: "Wesite Design - Accountancy Agency",
      client: "NGS Accountancy",
      langages: "Mobirise, html, CSS",
      link: "https://www.nickstark.co.uk/",
      linkText: "nickstark.co.uk",
      description:
        " A website design for a Formby based accountancy agency",
    },
	{
      id: "10",
      tag: "Web Design",
      title: "Minecraft Gaming Website",
      img: minecraft,
      imgSmall: minecraft,
      bg: "#F4F4FF",
	  project: "Minecraft Gaming Website",
      client: "Personal",
      langages: "HTML, CSS",
      link: "https://www.thginkkcalb.com/",
      linkText: "thginkkcalb.com",
      description:
        "This was a website I created when I was twelve years old. It generated 1,000 views per day and brought in advertising revenue. I also optimized it for search engines, achieving the number one ranking on Google for 'Try Minecraft,' which was a significant SEO accomplishment.",
    },
	{
      id: "11",
      tag: "Video",
      title: "Bermuda Triangle - Animation Sketch",
      img: animation1,
      imgSmall: animation1,
      bg: "#E9FAFF",
	  project: "Bermuda Triangle - Animation Sketch",
      client: "Personal",
      langages: "ToonBoom",
      link: "https://youtu.be/nsUcUJSD7Rw",
      linkText: "youtu.be/nsUcUJSD7Rw",
      description:
        "What Happens In The Bermuda Triangle - Animation sketch about an alien abduction. (2016)",
    },
	{
      id: "12",
      tag: "Video",
      title: "Julia Michaels - Anxiety (Fan Video)",
      img: juliamichaelsanxiety,
      imgSmall: juliamichaelsanxiety,
      bg: "#E9FAFF",
	  project: "Julia Michaels - Anxiety (Fan Video)",
      client: "Personal",
      langages: "English",
      link: "https://youtu.be/rz3Gdkm7OqY",
      linkText: "youtu.be/rz3Gdkm7OqY",
      description:
        " This music video was produced by me as part of an university project, And featured me as the masked actor - script, shot, edited and filmed by me. (2019)",
    },
	
	{
      id: "14",
      tag: "Video",
      title: "Hosting VFX Adversting Concept",
      img: vfxhostingadvert,
      imgSmall: vfxhostingadvert,
      bg: "#E9FAFF",
	  project: "Hosting VFX Adversting Concept",
      client: "Personal",
      langages: "Text",
      link: "https://youtu.be/7pLU_geYM00",
      linkText: "youtu.be/7pLU_geYM00",
      description:
        " Adversting concept animation for website hosting. ",
    },
	
	
	{
      id: "28",
      tag: "Graphics",
      title: "Voice Cloner - Paid Ad Design",
      img: voiceclone,
      imgSmall: voiceclone,
      bg: "#FFF0F0",
	  project: "Voice Cloner - Paid Ad Design",
      client: "aiXplain",
      langages: "Photoshop",
      link: "https://aixplain.com/voice-translation-ai/",
      linkText: "aixplain.com/voice-translation-ai/",
      description:
        "Paid Advertising banner for an online voice cloner.",
    },
	{
      id: "30",
      tag: "Graphics",
      title: "Product Demo - YouTube Thumnail",
      img: designexplained,
      imgSmall: designexplained,
      bg: "#FFF0F0",
	  project: "Product Demo - YouTube Thumnail",
      client: "aiXplain",
      langages: "Photoshop",
      link: "",
      linkText: "N/A",
      description:
        "Product demo, YouTube thumnail.",
    },
		{
      id: "29",
      tag: "Graphics",
      title: "HostMeNow.org Adversting Banner x2",
      img: superfasthosting,
      imgSmall: superfasthosting,
      bg: "#FFF0F0",
	  project: "Adversting Banner",
      client: "Personal",
      langages: "Photoshop",
      link: "https://www.HostMeNow.org",
      linkText: "HostMeNow.org",
      description:
        "Single sample advertising banner for my company 'HostMeNow'",
    },
		{
      id: "31",
      tag: "Graphics",
      title: "HostMeNow.org Adversting Banner x3",
      img: banner3,
      imgSmall: banner3,
      bg: "#FFF0F0",
	  project: "Adversting Banner",
      client: "Personal",
      langages: "Photoshop",
      link: "https://www.HostMeNow.org",
      linkText: "HostMeNow.org",
      description:
        "Single sample advertising banner for my company 'HostMeNow'",
    },
	{
      id: "19",
      tag: "Graphics",
      title: "HostMeNow.org Adversting Banner",
      img: cardimgredditsmall,
      imgSmall: cardimgredditsmall,
      bg: "#FFF0F0",
	  project: "Adversting Banner",
      client: "Personal",
      langages: "Photoshop",
      link: "https://www.HostMeNow.org",
      linkText: "HostMeNow.org",
      description:
        "Single sample advertising banner for my company 'HostMeNow'",
    },
	{
      id: "18",
      tag: "Graphics",
      title: "EverCity Logo Design",
      img: evercity,
      imgSmall: evercity,
      bg: "#FFF0F0",
	  project: "EverCity Logo Design",
      client: "EverCity",
      langages: "Photoshop",
      link: "",
      linkText: "EverCity",
      description:
        " Logo design for a gaming fourm 'EverCity. (2015)",
    },
	{
      id: "24",
      tag: "Graphics",
      title: "Gaming Clan Logo",
      img: rsz_awerino,
      imgSmall: rsz_awerino,
      bg: "#F4F4FF",
	  project: "Gaming Clan Logo",
      client: "Awerino",
      langages: "Photoshop",
      link: "",
      linkText: "",
      description:
        "Logo design for a gaming group. (2015)",
    },
	      ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
{
      id: "10",
      img: genai,
      imgSmall: genai,
      date: "2024 Jul",
      category: "aiXplain",
      title: "aiXplain and Pi Campus Hackathon 2024",
      bg: "#FCF4FF",
      description:
        "https://aixplain.com/blog/aixplain-picampus-hackathon-2024/",
    },   
	{
      id: "11",
      img: kubeflowtoflyte,
      imgSmall: kubeflowtoflyte,
      date: "2024 Jul",
      category: "aiXplain",
      title: "From Kubeflow to Flyte, A More Reliable ML Orchestration",
      bg: "#FCF4FF",
      description:
        "https://aixplain.com/blog/from-kubeflow-to-flyte-a-more-reliable-ml-orchestration-foundation",
    }, 
{
      id: "9",
      img: TranslationPipelines,
      imgSmall: TranslationPipelines,
      date: "2022 Jul",
      category: "aiXplain",
      title: "Rapid Prototyping and Deployment of Translation Pipelines with aiXplain Beta",
      bg: "#FCF4FF",
      description:
        "https://redr.me/vlakly/",
    },   
{
      id: "8",
      img: NAACL2022,
      imgSmall: NAACL2022,
      date: "2022 Jul",
      category: "aiXplain",
      title: "Exciting Research, Great Discussion, and Plenty of Fun at NAACL 2022",
      bg: "#FCF4FF",
      description:
        "https://redr.me/914cj5/",
    },   
   {
      id: "7",
      img: acllandscape,
      imgSmall: acllandscape,
      date: "2022 June",
      category: "aiXplain",
      title: "Our retrospective on the ACL 2022 Diversity and Inclusion initiative",
      bg: "#FCF4FF",
      description:
        "https://redr.me/2mezsl/",
    },
	{
      id: "1",
      img: kareemhands,
      imgSmall: kareemhands,
      date: "2022 June",
      category: "aiXplain",
      title: "10 Things You Did Not Know about the ACL Conference",
      bg: "#FCF4FF",
      description:
        "https://redr.me/mzq59k/ ",
    },
    {
      id: "4",
      img: whentochoose,
      imgSmall: whentochoose,
      date: "2022 June",
      category: "aiXplain",
      title: "When to Choose Text-To-Speech Voices for improved media performance",
      bg: "#EEFBFF",
      description:
        "https://redr.me/u0vvvq/",
    },
    {
      id: "2",
      img: fivecrypto,
      imgSmall: fivecrypto,
      date: "21 April",
      category: "Web Design",
      title: "Best Web Hosts That Accept Crypto",
      bg: "#FFF0F0",
      description:
        "https://hostmenow.org/blog/best-web-hosts-that-accept-crypto/",
    },
    {
      id: "5",
      img: Dogecoin,
      imgSmall: Dogecoin,
      date: "2022 Feb",
      category: "HostMeNow",
      title: "Where to spend my Dogecoin?",
      bg: "#FCF4FF",
      description:
        "https://hostmenow.org/blog/where-can-i-spend-dogecoin/ ",
    },
    {
      id: "3",
      img: BestPayPalWebHosting,
      imgSmall: BestPayPalWebHosting,
      date: "2022 Jan",
      category: "HostMeNow",
      title: "Best Web Hosts That Accepts PayPal",
      bg: "#FCF4FF",
      description:
        "SEO article for 'Best Web Hosts that Accept PayPal' - Google Postion 4. 																		https://hostmenow.org/blog/best-web-hosts-that-accept-paypal/",
    },
    {
      id: "6",
      img: reddit,
      imgSmall: reddit,
      date: "2021",
      category: "HostMeNow",
      title: "Tips for Gaining Upvotes on Reddit",
      bg: "#EEFBFF",
      description:
        "Test SEO article for 'Tips for Gaining Upvotes on Reddit' -  Google Position 3 						https://hostmenow.org/blog/10-tips-for-gaining-upvotes-on-reddit/",
    },
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/home/homePage",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/home/about",
      icon: <FaRegUser />,
    },
    {
      id: "06",
      name: "Resume",
      link: "/home/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Portfilo",
      link: "/home/portfilo",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Blogs",
      link: "/home/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/home/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "About",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Portfolio",
      link: "/portfolio",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Blogs",
      link: "/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
	ecologix,
	HostMeNow,
    img4,
    img1,
    img2,
	ecologix,
	HostMeNow,
    img4,
  ];

  // experience items for about page
  const experienceArray = [
    
    {
      id: "2",
      icon: icon1,
      title: "Digital Marketing",
      des: "With experience in various digital marketing tools and strategies, I know how to utilize SEO, social media marketing, email marketing, and PPC advertisements effectively.",
      color: "#DDA10C",
      bg: "#FBFAFC",
    },
    {
      id: "3",
      icon: icon2,
      title: "Photography",
      des: "I love photography-both taking and editing pictures. I have developed my skills over a great deal of time, have a good eye for composition and lighting, and can capture beautiful images that tell a story.",
      color: "#8774FF",
      bg: "#FBFAFC",
    },
    {
      id: "4",
      icon: icon3,
      title: "Videography",
      des: "7+ years experience in creating video content, both personal and professional. I have a deep understanding of the art form. My skills include filming, editing, scriptwriting and VFX. ",
      color: "#FF6080",
      bg: "#FBFAFC",
    },
    {
      id: "5",
      icon: icon4,
      title: "Business Managment",
      des: "From running my own part-time company 'HostMeNow LTD', I have developed strong business and marketing skills. These skills have been essential in growing my business and my skillset.",
      color: "#FF75D8",
      bg: "#FBFAFC",
    },
    {
      id: "6",
      icon: icon5,
      title: "Web Development",
      des: "I have a good foundation in web development, with experience in building and maintaining websites. I am experienced in using Adobe Muse, Webflow, as well as popular Content Management Systems like WordPress and Shopify. However, I am not a coder-- But I am comfortable working with code, and can make basic edits when necessary.",
      color: "#269FFF",
      bg: "#FBFAFC",
    },
	{
      id: "1",
      icon: icon,
      title: "Ui/Ux Design",
      des: "I have a good eye for detail and I am able to create designs that are both aesthetically pleasing and user-friendly. Experience in Photoshop, XMind, Miro & Figma.",
      color: "#D566FF",
      bg: "#FBFAFC",
    },
  ];

  // Resume items for Resume page
  const resumeArray = [
    {
      type: "Education",
      icon: MdOutlineSchool,
      id: "01",
      date: "2017-2021",
      title: "BA - Film & Television Production",
      place: " Edge Hill University, England",
      bg: "#ECFFF6",

      id1: "02",
      date1: "2019 - 2020",
      title1: "ERASMUS | Film Production",
      place1: "Film Academy of Miroslav Ondříček, Czech Republic",
      bg1: "#EAF4F0",

      id2: "03",
      date2: "2014 - 2016",
      title2: "Film & Television Production",
      place2: "Liverpool Media Academy (LMA)",
      bg2: "#ECFFF6",
    },
    {
      type: "Experience",
      icon: MdOutlineBusinessCenter,
      id: "04",
      date: "April 2022 - Present",
      title: "Content Manager",
      place: "aiXplain.com - AI and NLP Tech Startup,         USA, San Jose - Remote",
      bg: "#EEF5FA",

      id1: "05",
      date1: "Aug 2021 - Sep 2021",
      title1: "Marketing Specalist",
      place1: "Farmable.tech - AgTech Startup, Norway, Oslo - Remote",
      bg1: "#F2F4FF",

      id2: "06",
      date2: "2022 - Present",
      title2: "Part-Time Business",
      place2: "HostMeNow LTD - Website Hosting Solutions",
      bg2: "#EEF5FA",
    },
    {
      type: "Awards",
      icon: FaAward,
      id: "07",
      date: "2015-2017",
      title: "  Graphic Designer",
      place: "Web Graphy, Los Angeles, CA",
      bg: "#FCF4FF",

      id1: "08",
      date1: "2014 - 2015",
      title1: "Jr. Web Developer",
      place1: "Creative Gigs.",
      bg1: "#FCF9F2",

      id2: "09",
      date2: "2015-2017",
      title2: "Best Freelancer",
      place2: "Fiver & Upwork Level 2 & Top Rated",
      bg2: "#FCF4FF",
    },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#FF6464",
      name: "Design",
      number: "80",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "Google/Microsoft Ads ",
      number: "60",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "SEO",
      number: "95",
    },
    {
      id: "03",
      color: "#CA56F2",
      name: "Photoshop",
      number: "95",
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Phone ",
      item1: "+452 666 386",
      item2: "+452 666 386",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "support@gmail.com",
      item2: "example@gmail.com",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "Address ",
      item1: "Maount View, Oval",
      item2: "Road, New York, USA",
      bg: "#F2F4FF",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    menuItemTwo,
    experienceArray,
    sliderImg,
    resumeArray,
    lineArray,
    contactArray,
  };
};

export default AllData;
